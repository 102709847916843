<template>
  <BCard
    header-tag="h2"
    header-bg-variant="ams-gray"
    header-border-variant="ams-header-orange"
    header-text-variant="black"
    header-class="border-bottom highlight-border"
    align="left"
    class="m3 shadow mt-3"
  >
    <template slot="header">
      {{ title }}
    </template>
    <Msntable
      v-if="rows"
      :rows="rows"
      :sm-two-col="smallTable"
      :header="header"
      :title="title"
    />
    <BButton
      v-if="navBtn"
      variant="ams-blue-dark"
      :to="navBtn.to"
      class="indent-xs"
      :name="navBtn.text + ' button'"
    >
      {{ navBtn.text }}
    </BButton>
    <slot />
  </BCard>
</template>
<script>
export default {
  components: {
    Msntable: () => import('./MsnTable.vue'),
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    rows: {
      type: Array,
      required: false,
      default: () => undefined,
    },
    navBtn: {
      type: Object,
      required: false,
      default: () => undefined,
    },
    onClickBtn: {
      type: Object,
      required: false,
      default: () => undefined,
    },
    smallTable: {
      type: Boolean,
      required: false,
      default: false,
    },
    header: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}

</script>
<style>
@import '../../assets/msn_vue.css';
a.btn:not(.btn-outline-primary):visited {
  color: white  !important;
}
</style>
